import { __awaiter } from "tslib";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { ENRICH_DRAWER_SECTION, useUserRoutePermissions } from '@groupby/enrich-console';
import { useActiveModelPath } from '@/routing/hooks/utils';
import { ModelType, Role, Routing } from '@/constants';
import { LocalizedMessage, useLocalization } from '@/localization';
import { dashboardTitleToLocalizedMessageKey, normalizeDashboardTitle } from '@/search-for-retail/analytics';
import { addError } from '@/store/actions/creators';
import { useActiveArea, useActiveUserSelector, useCollectionName } from '@/store/selectors';
import { generateCollectionAreaUrl } from '@/search-for-retail/utilities';
import { filterRoutesByUserAccess, patchDrawerRoutesWithFields } from './utils';
import { useAuthToken } from '@/auth/hooks/authentication';
import { TitleBadge } from '@/search-for-retail/generic/title-badge/title-badge.component';
import { StyledBetaWrapper } from './drawer/navigation-drawer.styles';
import { NavigationType } from '@/navigation/types';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { excludedDashboardsFromRoute } from '@/search-for-retail/analytics/analytics.utils';
import { logError } from '@/utils/logger';
const NavigationContext = createContext({
    sections: [],
    dashboards: [],
    isDrawerOpen: true,
    navigationTypeOpen: NavigationType.PRIMARY,
    setVisibleRouteSection: (_section) => {
    },
    setDrawerOpen: (_override, _open) => {
    },
    setNavigationType: (_navigationType) => {
    },
});
const isPathInRoutes = (routes, modelPath) => routes.some((route) => {
    var _a;
    const hasSubroutes = !!((_a = route.subroutes) === null || _a === void 0 ? void 0 : _a.length);
    return route.path === modelPath || (hasSubroutes && isPathInRoutes(route.subroutes, modelPath));
});
export const NavigationProvider = ({ dashboards, drawerConfig, children, }) => {
    var _a;
    const dispatch = useDispatch();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const { getDrawerRoutes } = useUserRoutePermissions();
    const activeUser = useActiveUserSelector();
    const collectionName = useCollectionName();
    const areaName = useActiveArea();
    const token = useAuthToken();
    const { formatMessage: t } = useLocalization();
    const isNewSearchPerformanceDashboardEnabled = featureFlagsService.isFeatureEnabled(FeatureFlag.EnableNewSearchPerformanceDashboard);
    const isPipelineHealthEnabled = featureFlagsService.isFeatureEnabled(FeatureFlag.EnablePipelineHealth);
    const isDataQualityEnabled = featureFlagsService.isFeatureEnabled(FeatureFlag.DataQuality);
    const modelPath = useActiveModelPath();
    // local state
    const [sections, setSections] = useState((_a = drawerConfig === null || drawerConfig === void 0 ? void 0 : drawerConfig.sections) === null || _a === void 0 ? void 0 : _a.map((section) => {
        var _a;
        return (Object.assign(Object.assign({}, section), { categories: (_a = section === null || section === void 0 ? void 0 : section.categories) === null || _a === void 0 ? void 0 : _a.map((category) => (Object.assign(Object.assign({}, category), { routes: filterRoutesByUserAccess(category.routes, activeUser) }))) }));
    }));
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [navigationTypeOpen, setNavigationTypeOpen] = useState(NavigationType.PRIMARY);
    const setDrawerOpen = useCallback((override, openState) => {
        setIsDrawerOpen((prevValue) => (override ? openState : !prevValue));
    }, []);
    const setNavigationType = useCallback((navigationType) => {
        setNavigationTypeOpen(navigationType);
    }, []);
    const [visibleRouteSection, setVisibleRouteSection] = useState(sections === null || sections === void 0 ? void 0 : sections.find((section) => { var _a; return !!((_a = section === null || section === void 0 ? void 0 : section.categories) === null || _a === void 0 ? void 0 : _a.find((category) => isPathInRoutes(category.routes, modelPath))); }));
    const setRoutesByLabel = useCallback((label, categories, fieldsToPatch) => {
        const securedCategories = categories === null || categories === void 0 ? void 0 : categories.map((category) => (Object.assign(Object.assign({}, category), { routes: patchDrawerRoutesWithFields(filterRoutesByUserAccess(category.routes, activeUser), fieldsToPatch) }))).filter(({ routes }) => !!routes.length);
        setSections((prevSections) => prevSections === null || prevSections === void 0 ? void 0 : prevSections.map((section) => {
            if (section.label === label) {
                return Object.assign(Object.assign({}, section), { categories: securedCategories });
            }
            return section;
        }));
    }, [activeUser]);
    // fetch enrich categories
    useEffect(() => {
        const generateEnrichDrawerCategories = () => __awaiter(void 0, void 0, void 0, function* () {
            const enrichCategories = yield getDrawerRoutes(featureFlagsService);
            setRoutesByLabel(ENRICH_DRAWER_SECTION.label, enrichCategories, { sectionPath: Routing.SectionPath.ENRICH });
        });
        if (token) {
            void generateEnrichDrawerCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRoutesByLabel, token]);
    const betaBadge = useMemo(() => (<TitleBadge label={t({ key: 'BETA' })} size="small"/>), [t]);
    const dataQualityLabel = useMemo(() => (<StyledBetaWrapper>
      <LocalizedMessage messageKey="SEARCH_DATA_QUALITY"/>
      {betaBadge}
    </StyledBetaWrapper>), [betaBadge]);
    const searchPerformanceLabel = useMemo(() => (<StyledBetaWrapper>
      <LocalizedMessage messageKey="SEARCH_PERFORMANCE"/>
      {betaBadge}
    </StyledBetaWrapper>), [betaBadge]);
    const dataQualityRoutes = isDataQualityEnabled ? [{
            name: ModelType.SEARCH_DATA_QUALITY,
            path: Routing.MODEL_PATHS[ModelType.SEARCH_DATA_QUALITY],
            sectionPath: Routing.SectionPath.ANALYTICS,
            label: dataQualityLabel,
            roles: [Role.ADMIN, Role.MERCHANDISING],
        }] : [];
    const pipelineHealthRoutes = isPipelineHealthEnabled ? [{
            name: ModelType.PIPELINE_HEALTH,
            path: Routing.MODEL_PATHS[ModelType.PIPELINE_HEALTH],
            sectionPath: Routing.SectionPath.ANALYTICS,
            label: 'PIPELINE_HEALTH',
            roles: [Role.ADMIN, Role.MERCHANDISING],
        }] : [];
    const searchPerformanceRoute = {
        name: ModelType.SEARCH_PERFORMANCE,
        path: Routing.MODEL_PATHS[ModelType.SEARCH_PERFORMANCE],
        sectionPath: Routing.SectionPath.ANALYTICS,
        label: searchPerformanceLabel,
        roles: [Role.ADMIN, Role.MERCHANDISING],
    };
    const routes = [
        ...dataQualityRoutes,
        ...pipelineHealthRoutes,
    ];
    const drawerCategory = {
        label: 'DRAWER_CATEGORY_CHECK_SITE_HEALTH',
        routes,
    };
    const ENHANCE_PERFORMANCE_DASHBOARDS = dashboards.filter((dashboard) => !excludedDashboardsFromRoute.includes(dashboard.title));
    // build analytics categories
    useEffect(() => {
        const action = () => {
            var _a;
            try {
                const url = generateCollectionAreaUrl(collectionName, ModelType.ANALYTICS, areaName);
                const embeddedEnhancePerformanceDashboards = isNewSearchPerformanceDashboardEnabled
                    ? ENHANCE_PERFORMANCE_DASHBOARDS.filter((dashboard) => normalizeDashboardTitle(dashboard.title) !== ModelType.SEARCH_PERFORMANCE)
                    : ENHANCE_PERFORMANCE_DASHBOARDS;
                const categories = [
                    {
                        label: 'DRAWER_CATEGORY_ENHANCE_PERFORMANCE',
                        routes: [
                            ...(isNewSearchPerformanceDashboardEnabled ? [Object.assign({}, searchPerformanceRoute)] : []),
                            ...((_a = embeddedEnhancePerformanceDashboards === null || embeddedEnhancePerformanceDashboards === void 0 ? void 0 : embeddedEnhancePerformanceDashboards.map((dashboard) => ({
                                isDynamicRoute: true,
                                name: ModelType.ANALYTICS,
                                label: dashboardTitleToLocalizedMessageKey(dashboard.title),
                                path: `${Routing.MERCHANDISING_PATH}/analytics${url}${areaName ? `/${normalizeDashboardTitle(dashboard.title)}` : ''}`,
                                sectionPath: Routing.SectionPath.ANALYTICS,
                                roles: [Role.MERCHANDISING],
                                params: { dashboard },
                            }))) !== null && _a !== void 0 ? _a : []),
                        ],
                    },
                    ...(isDataQualityEnabled || isPipelineHealthEnabled ? [
                        Object.assign({}, drawerCategory),
                    ] : []),
                ];
                setRoutesByLabel('ANALYTICS', categories);
                if ((visibleRouteSection === null || visibleRouteSection === void 0 ? void 0 : visibleRouteSection.label) === 'ANALYTICS') {
                    const securedCategories = categories === null || categories === void 0 ? void 0 : categories.map((category) => (Object.assign(Object.assign({}, category), { routes: filterRoutesByUserAccess(category.routes, activeUser) }))).filter(({ routes }) => !!routes.length);
                    setVisibleRouteSection(Object.assign(Object.assign({}, visibleRouteSection), { categories: securedCategories }));
                }
            }
            catch (err) {
                const errorMessage = 'LOAD_ANALYTICS_ERROR';
                logError(errorMessage, err);
                dispatch(addError({ key: errorMessage }));
            }
        };
        void action();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboards, collectionName, areaName, dispatch, setRoutesByLabel]);
    return (<NavigationContext.Provider value={{
        sections,
        dashboards,
        visibleRouteSection,
        isDrawerOpen,
        navigationTypeOpen,
        setVisibleRouteSection,
        setDrawerOpen,
        setNavigationType,
    }}>
      {children}
    </NavigationContext.Provider>);
};
export default NavigationProvider;
export const useNavigation = () => useContext(NavigationContext);
