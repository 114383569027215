export default {
    API_HOST: 'groupbycloud.com',
    API_PATH: '/admin/v2',
    ENRICH_API_PREFIX: 'https://api.enrich-lo.groupbycloud.com',
    // ENRICH_API_PREFIX: 'https://api.enrich.groupbycloud.com',
    CC_ENRICH_API_PREFIX: 'https://ccapi.enrich-lo.groupbycloud.com/ccapi',
    // CC_ENRICH_API_PREFIX: 'https://ccapi.enrich.groupbycloud.com/ccapi',
    S4R_API_PREFIX: 'https://ccapi.gbiqa-lo.groupbycloud.com/ccapi',
    // S4R_API_PREFIX: 'https://ccapi.gbiqa.groupbycloud.com/ccapi',
    S4R_API_ENV: 'gbiqa-lo',
    // S4R_API_ENV: 'gbiqa',
    DYNAMIC_API: false,
    LOCAL_S4R_API_PREFIX: 'http://localhost:8080',
    QA_API_SUBDOMAIN: 'gbiqa',
    ENRICH_QA_API_SUBDOMAIN: 'enrich',
    SR_QA_API_SUBDOMAIN: 'sr-qa1',
    TARGET_CUSTOMER: '',
};
