import { Box } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '@/theme';
export const FullContainer = styled(Box) `
  && {
    height:  ${({ theme }) => `calc(100vh - ${theme.footer.height} - ${theme.appHeader.height})`};
    width: 100%;
    margin: 0;
    padding: 0;

    @media (max-width: ${breakpoints.md}) {
      height: ${({ theme }) => `calc(100vh - ${theme.footer.heightMobile} - ${theme.appHeader.height})`};
    }
  }
`;
export const AnalyticsWrapper = styled.div `
  width: 100%;
  top: 60px;
  position: relative;
`;
