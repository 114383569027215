import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { useDispatch } from 'react-redux';
import { getEmbedSsoUrl } from '../analytics.client';
import { AnalyticsWrapper, FullContainer } from '../analytics.styles';
import { AdhocMessageBar } from '@/adhoc-message/adhoc-message-bar.component';
import { addError } from '@/store/actions/creators';
import { creators } from '@/store/actions/creators/search-for-retail';
import { useActiveArea, useCollectionName } from '@/store/selectors';
import { useAuthToken } from '@/auth/hooks/authentication';
import { useGoToUrl, usePathname } from '@/routing/hooks/utils';
import { normalizeDashboardTitle } from '@/search-for-retail/analytics';
import { generateCollectionAreaUrl } from '@/search-for-retail/utilities';
import { ModelType, Routing } from '@/constants';
import { excludedDashboardsFromRoute } from '../analytics.utils';
import SplashScreen from '@/search-for-retail/splash-screen/splash-screen';
import { logError } from '@/utils/logger';
export const AnalyticsDashboard = ({ dashboard }) => {
    const dispatch = useDispatch();
    const collectionName = useCollectionName();
    const areaName = useActiveArea();
    const token = useAuthToken();
    const pathname = usePathname();
    const paths = pathname.split('/');
    const goToUrl = useGoToUrl();
    const [embedSsoUrl, setEmbedSsoUrl] = useState();
    const [showSplashScreen, setShowSplashScreen] = useState(false);
    useEffect(() => {
        const action = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!token || isNil(areaName) || !dashboard) {
                setShowSplashScreen(true);
                return;
            }
            try {
                const foundEmbedSsoUrl = yield getEmbedSsoUrl(token, { path: dashboard.embedPath, areaName });
                setEmbedSsoUrl(foundEmbedSsoUrl);
                setShowSplashScreen(false);
            }
            catch (error) {
                const errorMessage = 'LOAD_ANALYTICS_ERROR';
                logError(errorMessage, error);
                dispatch(addError({ key: errorMessage }));
                setShowSplashScreen(true);
            }
        });
        void action();
    }, [token, areaName, dispatch, dashboard]);
    useEffect(() => {
        if (isNil(embedSsoUrl)) {
            dispatch(creators.s4rSetLoadingAnalyticsDashboards(true));
        }
        else {
            dispatch(creators.s4rSetLoadingAnalyticsDashboards(false));
        }
    }, [dispatch, embedSsoUrl]);
    useEffect(() => {
        if (!token || isNil(collectionName) || isNil(areaName) || !dashboard)
            return;
        if (excludedDashboardsFromRoute.includes(dashboard.title))
            return;
        const dashboardFromRoute = paths[5];
        if (!dashboardFromRoute) {
            const url = generateCollectionAreaUrl(collectionName, ModelType.ANALYTICS, areaName);
            goToUrl(`${Routing.MERCHANDISING_PATH}/analytics${url}/${normalizeDashboardTitle(dashboard.title)}`);
        }
    }, [token, collectionName, areaName, paths, goToUrl, dashboard]);
    if (!dashboard || showSplashScreen) {
        return <SplashScreen />;
    }
    return (<AnalyticsWrapper>
      <React.Fragment>
        <FullContainer>
          <AdhocMessageBar placement="analytics-dashboard"/>
          <iframe id={dashboard.id.toString()} title={dashboard.title} src={embedSsoUrl} width="100%" height="100%" frameBorder="none"/>
        </FullContainer>
      </React.Fragment>
    </AnalyticsWrapper>);
};
export default AnalyticsDashboard;
